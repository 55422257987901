import Acl from 'browser-acl'
import {
  ROLE_EMPLOYEE,
  ROLE_ADMIN_EMPLOYEE,
  ROLE_DOCTOR_EMPLOYEE,
  ROLE_CHIEF_DOCTOR_EMPLOYEE,
  ROLE_EDITOR_EMPLOYEE,
  ROLE_IDDQD
} from "./models/AuthUser";

const acl = new Acl()

/**
 Пользователи - никто
 Клиники, врачи и услуги - admins
 Агенты - m.chistova
 Реестры - никто
 Блог - никто
 Фотопоток - admins
 Что мы лечим - никто
 ЛК МедПрофи - m.chistova
*/
acl.rule(['create', 'update', 'show'], 'User', (user) => user.isGod())
acl.rule(['login_to_account'], 'User', (user) => user.isGod() && user.canLoginToAccount())

// acl.rule(['create', 'update', 'delete', 'show'], 'WhatDoWeTreat', (user) => {
//   return user.roles.includes(ROLE_EDITOR_EMPLOYEE)
// })
acl.rule(['create', 'update', 'delete', 'show'], 'Post', (user) => {
  return user.roles.includes(ROLE_EDITOR_EMPLOYEE)
})

acl.rule(['show'], 'Appointment', (user) => user.isGod())
acl.rule(['show'], 'Payment', (user) => user.isGod())
acl.rule(['show'], 'SearchTerm', (user) => user.isGod())
acl.rule(['show'], 'UserFileHistory', (user) => user.isGod())
acl.rule(['show'], 'MedicalCardHistory', (user) => user.isGod())

acl.rule(['create', 'update', 'delete', 'show'], 'Clinic', (user) => user.getRoles().indexOf(ROLE_EDITOR_EMPLOYEE) > -1)
acl.rule(['create', 'update', 'delete', 'show'], 'Doctor', (user) => user.getRoles().indexOf(ROLE_EDITOR_EMPLOYEE) > -1)
acl.rule(['create', 'update', 'delete', 'show'], 'Service', (user) => user.getRoles().indexOf(ROLE_EDITOR_EMPLOYEE) > -1)
acl.rule(['create', 'update', 'delete', 'show'], 'ServiceCategory', (user) => user.isAdmin())
acl.rule(['create', 'update', 'delete', 'show'], 'Speciality', (user) => user.isAdmin())
acl.rule(['create', 'update', 'delete', 'show'], 'Examination', (user) => user.isAdmin())
acl.rule(['create', 'update', 'delete', 'show'], 'HouseCallMap', (user) => user.isAdmin())
acl.rule(['create', 'update', 'show'], 'Photo', (user) => {
  return user.getRoles().indexOf(ROLE_EDITOR_EMPLOYEE) > -1
})
acl.rule(['create', 'update', 'delete', 'show'], 'Jobs', (user) => user.isEditor())
acl.rule(['create', 'update', 'delete', 'show'], 'Pages', (user) => user.isEditor())
acl.rule(['create', 'show'], 'Cme', (user) => user.isDoctor())

acl.rule(['show'], 'Messengers', (user) => user.isAdmin())

acl.rule(['create', 'update', 'delete', 'show'], 'Collab', (user) => user.isAgentsAdmin())
acl.rule(['login_to_account'], 'Collab', (user) => user.isAgentsAdmin() && user.canLoginToAccount())
acl.rule(['create', 'update', 'delete', 'show'], 'Agent', (user) => user.isAgentsAdmin())
acl.rule(['show_calendar'], 'Doctor', (user) => user.isDoctor())

acl.rule(['refund'], 'Transaction', (user) => user.isGod())
acl.rule(['unbind'], 'BankCard', (user) => user.isGod())

acl.rule(['show'], 'InformerNotifications', (user) => user.isEditor())
acl.rule(['show'], 'Cart', (user) => {
  return user.roles.indexOf(ROLE_EMPLOYEE) > -1
})
acl.rule(['show'], 'PaysheetsChief', (user) => {
  return user.isGod() || user.getRoles().indexOf(ROLE_CHIEF_DOCTOR_EMPLOYEE) > -1
})
acl.rule(['show'], 'Paysheets', (user) => {
  return user.roles.indexOf(ROLE_EMPLOYEE) > -1
})
acl.rule(['show'], 'MainPage', (user) => {
  return user.isEditor()
})
acl.rule(['show'], 'Cache', (user) => {
  return user.roles.indexOf(ROLE_EMPLOYEE) > -1
})
acl.rule(['show'], 'Files', (user) => {
  return user.roles.indexOf(ROLE_EDITOR_EMPLOYEE) > -1
})
acl.rule(['show'], 'File', (user) => {
  return user.roles.indexOf(ROLE_EDITOR_EMPLOYEE) > -1
})
acl.rule(['show'], 'RequestForm', (user) => {
  return user.roles.indexOf(ROLE_IDDQD) > -1
})
acl.rule(['show'], 'Config', (user) => {
  return user.roles.indexOf(ROLE_IDDQD) > -1
})
acl.rule(['show'], 'Official', (user) => {
  return user.roles.indexOf(ROLE_EMPLOYEE) > -1
})
export default acl
